<template>
  <div>
    <component
      :is="componentName"
      v-model="form"
      @cancel="cancel"
      @isValid="isValid"
      :errors="errors"
      @update-prop="updateValue($event, value)"
      ><template v-slot:buttons><slot name="buttons" /></template
    ></component>
  </div>
</template>

<script>
import formEditable from '@/mixins/form-editable';

export default {
  name: 'InhabitantChangesRequest',

  mixins: [formEditable],

  props: {
    action: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },

  components: {
    EditCheckin: () => import('./EditCheckinCard'),
    EditCheckout: () => import('./EditCheckoutCard'),
    CreateCheckin: () =>
      import('@/modules/inhabitant_changes/components/CreateCheckinCard'),
  },

  computed: {
    componentName() {
      return `${this.action}-${this.type}`.toLowerCase();
    },
  },

  methods: {
    cancel() {
      this.$emit('cancel');
    },
    isValid(v) {
      this.$emit('isValid', v);
    },
  },
};
</script>

<style scoped></style>
