<template>
  <v-form>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="inhabitantChanges.data"
      :server-items-length="inhabitantChanges.meta.total"
      sort-desc
      :footer-props="footerProps"
      :options.sync="options"
      sort-by="id"
    >
      <template v-slot:item.person.personal_profile.mobile_phone="{ item }">
        {{
          (item.person || item.data)
            | getNestedDotNotation('personal_profile.mobile_phone')
        }}
      </template>

      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | dateFormat }}
      </template>

      <template v-slot:item.type_id="{ item }">
        <v-chip outlined :color="item.type.color">
          {{ item.type.name }}
        </v-chip>
      </template>

      <template v-slot:item.action="{ item }">
        <div class="text-center">
          <v-list-item @click="$emit('edit', item)">
            <v-list-item-title>
              <v-icon class="mr-1" small>mdi-eye</v-icon>
              {{ $t('ITEM.VIEW') }}
            </v-list-item-title>
          </v-list-item>
        </div>
      </template>
    </v-data-table>
  </v-form>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { isEmpty, cloneDeep } from 'lodash';
import { dateFormat, getNestedDotNotation } from '@/filters';

export default {
  name: 'InhabitantChangesList',

  filters: {
    dateFormat,
    getNestedDotNotation,
  },

  props: {
    inhabitantChanges: {
      type: Object,
      default: () => ({
        data: [],
        meta: {
          total: 0,
        },
      }),
    },
  },

  data: () => ({
    error: null,
    options: {},
    footerProps: {
      itemsPerPageOptions: [10, 50, 100],
    },
  }),

  computed: {
    ...mapState('inhabitant_change', {
      prevOptions: 'options',
    }),

    loading() {
      return this.$store.getters['loading'];
    },

    params() {
      const options = this.options;

      return {
        page: options.page,
        per_page: options.itemsPerPage,
        sort_by: options.sortBy[options.sortBy.length - 1],
        order_by: options.sortDesc[options.sortDesc.length - 1]
          ? 'desc'
          : 'asc',
      };
    },

    headers() {
      return [
        {
          text: this.$t('ITEM.ID'),
          align: 'left',
          sortable: true,
          value: 'id',
        },
        {
          text: this.$t('PROFILE.FIRST_NAME'),
          value: 'first_name',
        },
        {
          text: this.$t('PROFILE.LAST_NAME'),
          value: 'last_name',
        },
        {
          text: this.$t('PROFILE.MOBILE_PHONE'),
          value: 'person.personal_profile.mobile_phone',
          sortable: false,
        },
        {
          text: this.$t('PROFILE.STREET'),
          value: 'apartment.building.street',
        },
        {
          text: this.$t('PROFILE.ZIP'),
          value: 'apartment.building.building_complex.zip',
        },
        {
          text: this.$t('PROFILE.CITY'),
          value: 'apartment.building.building_complex.city',
        },
        {
          text: this.$t('BUILDING_COMPLEXES.ITEM'),
          value: 'apartment.building.building_complex.name',
        },
        {
          text: this.$t('APARTMENTS.ITEM'),
          value: 'apartment.name',
        },
        {
          text: this.$t('ITEM.REQUEST_DATE'),
          value: 'created_at',
        },
        {
          text: this.$t('ITEM.TYPE'),
          value: 'type_id',
        },
        {
          text: this.$t('DATA_TABLE.ACTIONS'),
          value: 'action',
          sortable: false,
          align: 'center',
        },
      ];
    },
  },

  watch: {
    options: {
      handler() {
        this.refreshData();
      },
      deep: true,
    },
  },

  created() {
    if (!isEmpty(this.prevOptions)) {
      this.options = cloneDeep(this.prevOptions);
    }
  },

  beforeDestroy() {
    this.setPageOptions({ pageOptions: this.options });
  },

  methods: {
    ...mapActions('inhabitant_change', ['setPageOptions']),

    refreshData() {
      this.$emit('refresh', {
        params: Object.assign({}, this.params),
      });
    },
  },
};
</script>
