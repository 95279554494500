<template>
  <div>
    <v-toolbar>
      <v-toolbar-title>{{
        $t('INHABITANT_CHANGES.MANAGEMENT')
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn dark color="indigo" @click="createInhabitantChange">
        <v-icon left>mdi-plus</v-icon>
        {{ $t('ITEM.NEW') }} {{ $t('PERSONS.ITEM') }}
      </v-btn>
    </v-toolbar>

    <v-sheet class="mt-5 pa-5" tile>
      <inhabitant-changes-list
        :inhabitantChanges="inhabitantChanges"
        @edit="editedInhabitantChange"
        @refresh="refreshData"
      />

      <v-dialog
        v-if="dialog && form"
        v-model="dialog"
        persistent
        max-width="900px"
      >
        <inhabitant-changes-request
          v-if="form"
          v-model="form"
          :action="action"
          :type="getTypeItem.form"
          @cancel="cancel"
          @isValid="isValid"
          :errors="errors.getAll()"
          @update-prop="clearErrorByName($event)"
        >
          <template v-slot:buttons>
            <v-btn large outlined depressed color="grey" @click="cancel">{{
              $t('BUTTON.CANCEL')
            }}</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              v-if="action === actions.CREATE"
              large
              depressed
              color="primary"
              @click="create"
              :disabled="!valid"
            >
              {{ $t('BUTTON.CREATE') }}
            </v-btn>

            <v-btn
              v-if="action === actions.EDIT"
              large
              depressed
              color="primary"
              @click="update"
              :disabled="!valid"
            >
              {{ $t('BUTTON.UPDATE') }}
            </v-btn>
          </template>
        </inhabitant-changes-request>
      </v-dialog>
    </v-sheet>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import InhabitantChangesList from '@/modules/inhabitant_changes/components/InhabitantChangesList';
import { InhabitantChangesService } from '@/services/inhabitant-changes.service';
import InhabitantChangesRequest from '@/modules/inhabitant_changes/components/InhabitantChangesRequest';
import errors from '@/mixins/errors';
import actions from '@/modules/inhabitant_changes/common/actions';
import types from '@/modules/inhabitant_changes/common/types';
import { CHECKIN } from '@/modules/inhabitant_changes/common/types';

export default {
  name: 'InhabitantChangesPage',

  components: {
    InhabitantChangesRequest,
    InhabitantChangesList,
  },

  mixins: [errors],

  data: () => ({
    inhabitantChanges: undefined,
    form: undefined,
    valid: undefined,
    dialog: false,
    action: '',
    params: {},
    actions: actions,
  }),

  computed: {
    getTypeItem() {
      return types.find((x) => x.id === this.form.type_id);
    },
  },

  mounted() {
    this.getInhabitantChanges();
  },

  methods: {
    ...mapActions('inhabitant_change', ['getInhabitantChanges']),

    isValid(val) {
      this.valid = val;
    },

    closeDialog() {
      this.dialog = false;
      this.form = undefined;
    },

    openDialog() {
      this.dialog = true;
      this.valid = true;
    },

    refreshData(params = this.params) {
      this.params = Object.assign({}, params);
      this.getInhabitantChanges(params)
        .then(({ data }) => {
          this.inhabitantChanges = data;
        })
        .catch((error) => {
          this.error = error.response;
        });
    },

    createInhabitantChange() {
      this.form = { type_id: CHECKIN };
      this.action = actions.CREATE;
      this.openDialog();
    },

    editedInhabitantChange(inhabitantChange) {
      InhabitantChangesService.getById(inhabitantChange.id).then(({ data }) => {
        this.form = data.data;
      });
      this.action = actions.EDIT;
      this.openDialog();
    },

    create() {
      InhabitantChangesService.create(this.form.data)
        .then(() => {
          this.closeDialog();
          this.refreshData();
        })
        .catch((response) => {
          this.handleErrorsByResponse(response);
        });
    },

    update() {
      InhabitantChangesService[this.getTypeItem.update](this.form.id, {
        ...this.form.data,
        status_id: this.form.status_id,
      })
        .then(() => {
          this.closeDialog();
          this.refreshData();
        })
        .catch((response) => {
          this.handleErrorsByResponse(response);
        });
    },

    cancel() {
      this.closeDialog();

      this.errors.clear();
    },
  },
};
</script>
