import { isEqual, get } from 'lodash';

export const CREATE = 'create';
export const EDIT = 'edit';
export const SHOW = 'show';

export const views = [CREATE, EDIT, SHOW];

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    action: {
      type: String,
      default: CREATE,
      validator: (prop) => views.includes(prop),
    },
  },

  data: () => ({
    form: {},
  }),

  watch: {
    value: {
      deep: true,
      immediate: true,
      handler: 'valueWatchHandler',
    },
  },

  methods: {
    valueWatchHandler(formProps) {
      if (isEqual(formProps, this.form)) {
        return;
      }

      this.form = {
        ...this.form,
        ...formProps,
      };

      this.emitter();
    },

    emitter() {
      this.$emit('input', this.form);
    },

    updateValue(key, value) {
      this.updateProp(key, value);
      this.emitter();
    },

    updateProp(key, value) {
      this.$emit('update-prop', key, value);
    },

    updatePropWrapPrefix(key, value, prefix) {
      this.updateProp(prefix ? `${prefix}.${key}` : key, value);
    },

    getRulesByName(name) {
      if (this.readonly) {
        return [];
      }

      return this.rules ? get(this.rules, name) : [];
    },

    getErrorsByName(name) {
      if (this.readonly) {
        return [];
      }

      return this.errors ? get(this.errors, name) : [];
    },
  },
};
