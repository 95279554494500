import { DateTime } from 'luxon';
import { getType } from '@/utils';

export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';
export const DEFAULT_TIME_FORMAT = 'HH:mm:ss';

/**
 * Parse date by Luxon
 *
 * @param date
 * @param opts
 *
 * @returns {DateTime|{null}}
 */
export const parseDateToLuxon = function (date, opts = {}) {
  if (!date) {
    return null;
  }

  if (date instanceof DateTime) {
    return date;
  }

  let method = null;
  let methods = null;
  let luxonDate = null;

  const type = getType(date);

  switch (type) {
    case 'string':
      methods = ['fromSQL', 'fromISO', 'fromHTTP', 'fromRFC2822', 'fromFormat'];
      break;
    case 'number':
      methods = ['fromMillis', 'fromSeconds'];
      break;
    case 'date':
      methods = ['fromJSDate'];
      break;
    case 'object':
      methods = ['fromObject'];
      break;
    default:
      return null;
  }

  do {
    method = methods.shift();
    luxonDate = DateTime[method](date, opts);
  } while (!luxonDate.isValid && methods.length);

  if (!luxonDate.isValid && type === 'string') {
    luxonDate = DateTime.fromJSDate(new Date(date), opts);
  }

  return luxonDate.isValid ? luxonDate : null;
};

/**
 * Compare dates
 *
 * @param a {DateTime|Date|number|string|object}
 * @param b {DateTime|Date|number|string|object}
 *
 * @returns {number}
 *
 * @throws {Error} throws an error if a or b is not date or was not converted to date
 */
export const compareDates = function (a, b) {
  a = parseDateToLuxon(a);
  b = parseDateToLuxon(b);

  if (a === null || b === null) {
    throw new Error('Invalid dates');
  }

  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }

  return 0;
};
