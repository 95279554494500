var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.inhabitantChanges.data,"server-items-length":_vm.inhabitantChanges.meta.total,"sort-desc":"","footer-props":_vm.footerProps,"options":_vm.options,"sort-by":"id"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.person.personal_profile.mobile_phone",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getNestedDotNotation")((item.person || item.data),'personal_profile.mobile_phone'))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.created_at))+" ")]}},{key:"item.type_id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"outlined":"","color":item.type.color}},[_vm._v(" "+_vm._s(item.type.name)+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-list-item',{on:{"click":function($event){return _vm.$emit('edit', item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-eye")]),_vm._v(" "+_vm._s(_vm.$t('ITEM.VIEW'))+" ")],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }