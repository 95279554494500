import { DateTime } from 'luxon';

import config from '@/config';
import { parseDateToLuxon } from '@/helpers/datetime';

export const toLower = function toLower(value) {
  return value ? value.toString().toLowerCase() : '';
};

export const capitalize = function capitalize(value) {
  return value ? value.charAt(0).toUpperCase() + value.slice(1) : '';
};

export const getNested = function getNested(obj, ...args) {
  return args.reduce((obj, level) => obj && obj[level], obj);
};

export const getNestedDotNotation = function getNestedDotNotation(obj, string) {
  return getNested(obj, ...string.split('.'));
};

export const dateFormat = function dateFormat(value, fallback = '', opts = {}) {
  if (!value) {
    return fallback;
  }
  const date = parseDateToLuxon(value, opts);
  return date ? date.toFormat('dd.MM.yyyy') : '';
};

export const dateFormatBackend = function dateFormatBackend(
  value,
  fallback = '',
  opts = {}
) {
  if (!value) {
    return fallback;
  }

  const date = parseDateToLuxon(value, opts);

  return date ? date.toFormat('yyyy-MM-dd') : '';
};

export const dateTimeFormat = function dateTimeFormat(
  value,
  fallback = '',
  opts = {}
) {
  if (!value) {
    return fallback;
  }

  const date = parseDateToLuxon(value, opts);

  return date ? date.toFormat('dd.MM.yyyy HH:mm') : '';
};

export const timeFormat = function timeFormat(value, fallback = '') {
  if (!value) {
    return fallback;
  }

  return DateTime.fromISO(value).toFormat('HH:mm');
};

export const periodFormat = function dateFormat(value, fallback = '') {
  if (!value) {
    return fallback;
  }

  const locale = Storage.get('locale') || config.get('app.i18nLocale', 'de');

  return DateTime.fromISO(value).setLocale(locale).toFormat('MMMM YYYY');
};

export const priceFormat = function priceFormat(value, digits = 2) {
  const preparedValue = parseFloat(value);

  return !isNaN(preparedValue) && isFinite(value)
    ? preparedValue.toFixed(digits).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    : value;
};

export const minutesToDuration = function minutesToDuration(value) {
  const parsedValue = parseInt(value);

  if (isNaN(parsedValue) || !isFinite(parsedValue)) {
    return value;
  }

  let hh = (parsedValue / 60) | 0;
  let mm = parsedValue % 60;

  if (hh < 0 && mm < 0) {
    mm = Math.abs(mm);
  }

  if (hh === 0) {
    return `${mm}m`;
  }

  if (mm === 0) {
    return `${hh}h`;
  }

  return `${hh}h ${mm}m`;
};

export const secondsToDuration = function secondsToDuration(value) {
  const parsedValue = parseInt(value);

  if (isNaN(parsedValue) || !isFinite(parsedValue)) {
    return value;
  }

  return minutesToDuration(parsedValue / 60);
};
